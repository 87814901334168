import { useEffect } from "react";

const useSwipeToScroll = (element) => {
  useEffect(() => {
    if (!element) {
      return;
    }

    let scrollLeft;
    let startX;

    const handleMouseUp = (e) => {
      if (startX === undefined) {
        return;
      }

      const deltaX = e.clientX - startX;
      element.scrollLeft = scrollLeft - deltaX;
    };

    const handleMouseDown = (e) => {
      e.preventDefault();
      startX = e.clientX;
      scrollLeft = element.scrollLeft;
    };

    element.addEventListener("mousedown", handleMouseDown);
    element.addEventListener("mouseup", handleMouseUp);

    return () => {
      element.removeEventListener("mousedown", handleMouseDown);
      element.removeEventListener("mouseup", handleMouseUp);
    };
  }, [element]);
};

export default useSwipeToScroll;
