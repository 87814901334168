import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import slugify from "slugify";

import EditorialTitle from "@/components/EditorialTitle";
import VideoPlayer from "@/components/VideoPlayer";
import EditorialCard2Column from "@/components/cards/editorial-card-2-column";
import useBreakpoint from "@/hooks/useBreakpoint";
import useSwipeToScroll from "@/hooks/useSwipeToScroll";

import styles from "./EditorialGrid2ColumnModule.module.scss";

const EditorialGrid2ColumnModule = ({ data }) => {
  const { isDesktop } = useBreakpoint();
  const cardsRef = useRef(null);
  const moduleRef = useRef(null);

  const { cards, editorialTitle, subNavLabel, anchorTag } = data;

  const sectionSlug = slugify(subNavLabel || "").toLowerCase();
  const scrollSlug = slugify(subNavLabel || "").toLowerCase();

  useSwipeToScroll(cardsRef.current);

  /**
   *
   */
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        end: "center center",
        scrub: true,
        start: "top center",
        trigger: moduleRef.current,
      },
    });

    if (isDesktop) {
      tl.add(
        gsap.to(`.${styles.module} .${styles.cards} > *`, {
          ease: "power1.inOut",
          transform: "translateY(0px)",
        }),
        0
      );
    }
  }, [isDesktop]);

  return (
    <section
      className={styles.module}
      data-attr-module-name="module-editorial-grid-two-column"
      data-attr-scroll={anchorTag || scrollSlug}
      data-id="EditorialGrid2ColumnModule"
      id={sectionSlug}
      ref={moduleRef}
    >
      <EditorialTitle data={editorialTitle} />

      <div className={styles.cards} ref={cardsRef}>
        {cards.map((card) => {
          const {
            sys: { id },
            fields: {
              media: { fields: mediaFields },
              title,
              subtitle,
              subcopy,
              tag,
              tertiaryCopy,
              urlRef,
              cta,
              eyebrow,
            },
          } = card;

          if (mediaFields.imageTitle) {
            return (
              <EditorialCard2Column
                colIndex={0}
                cta={cta}
                eyebrow={eyebrow}
                id={id}
                key={id}
                mediaFields={mediaFields}
                subcopy={subcopy}
                subtitle={subtitle}
                tag={tag}
                tertiaryCopy={tertiaryCopy}
                title={title}
                urlRef={urlRef}
              />
            );
          } else if (mediaFields.videoTitle) {
            const videoAsset = mediaFields.videoFile
              ? mediaFields.videoFile.fields.file
              : null;
            const posterSrc = mediaFields.coverImageFile
              ? `https:${mediaFields.coverImageFile.fields.file.url}`
              : null;

            return (
              <div className={styles.columnCard} key={id}>
                <VideoPlayer
                  data={{
                    posterSrc,
                    videoAsset,
                  }}
                  rewindOnPause={true}
                  subcopy={subcopy}
                  tag={tag}
                  tertiaryCopy={tertiaryCopy}
                  title={title}
                  urlRef={urlRef}
                />
              </div>
            );
          }

          console.error(
            `Error: Unable to render this Contentful Entry in moduleEditorialGrid2Column: "${JSON.stringify(
              mediaFields
            )}. Please check media type." `
          );
        })}
      </div>
    </section>
  );
};

EditorialGrid2ColumnModule.propTypes = {
  data: PropTypes.shape({
    anchorTag: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.object).isRequired,
    editorialTitle: PropTypes.object.isRequired,
    marginBottom: PropTypes.string,
    subNavLabel: PropTypes.string,
    subNavText: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default EditorialGrid2ColumnModule;
